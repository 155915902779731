import { createRoot } from 'react-dom/client';
import "./index.css"
import App from "./App"
import { GoogleOAuthProvider } from '@react-oauth/google';

import { I18nextProvider } from 'react-i18next'
import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector';
import global_en from './locales/en/global.json'
import global_ja from './locales/ja/global.json'
import { BrowserRouter as Router } from "react-router-dom";
import { GOOGLE_AUTH_CLIENT_KEY } from 'lib/api/client';

i18next
  .use(LanguageDetector)
  .init({
    detection: {
      order: ['navigator', 'htmlTag'],
      caches: ['localStorage', 'cookie'],
    },
    interpolation: { escapeValue: false },
    fallbackLng: 'en',
    resources: {
      en: {
        global: global_en,
      },
      ja: {
        global: global_ja,
      },
    },
  });

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <GoogleOAuthProvider clientId={GOOGLE_AUTH_CLIENT_KEY}>
    <I18nextProvider i18n={i18next}>
      <Router>
        <App />
      </Router>
    </I18nextProvider>
  </GoogleOAuthProvider>
);
