import React, { useState } from 'react';
import axios from 'axios';
import { Button, TextField, Snackbar, Alert, Box, Typography, Table, TableHead, TableRow, TableCell, TableBody, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next'; // Import i18n
import heic2any from 'heic2any'; // Import heic2any for HEIC conversion
import { BASE_URL } from 'lib/api/client';

interface Ingredient {
  name: string;
  purpose: string;
  safety: string;
}

interface ResponseData {
  ingredients: Ingredient[];
}

const ImageUploadPage: React.FC = () => {
  const { t } = useTranslation("global");

  const [image, setImage] = useState<File | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [response, setResponse] = useState<ResponseData | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  
  // Loading states
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState<string>('');

  // Handle file input
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const fileType = file.type;

      // Check if the file is PNG, JPEG, or HEIC
      if (fileType !== 'image/png' && fileType !== 'image/jpeg' && fileType !== 'image/heic') {
        setError(t('mainSection.ingredientTable.upload-image'));
        setImage(null);
        setSnackbarMessage(t('mainSection.ingredientTable.upload-image'));
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      } else {
        setImage(file);
        setError(null);
      }
    }
  };

  // Convert HEIC image to JPEG if necessary
  const convertHeicToJpeg = (file: File): Promise<File> => {
    return new Promise((resolve, reject) => {
      heic2any({ blob: file, toType: 'image/jpeg' })
        .then((convertedBlob) => {
          if (Array.isArray(convertedBlob)) {
            const convertedFile = new File([convertedBlob[0]], 'converted_image.jpg', { type: 'image/jpeg' });
            resolve(convertedFile);
          } else {
            const convertedFile = new File([convertedBlob], 'converted_image.jpg', { type: 'image/jpeg' });
            resolve(convertedFile);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // Convert image to PNG/JPEG if necessary (for non-HEIC files)
  const convertImageToJpeg = (file: File): Promise<File> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const img = new Image();
        img.src = reader.result as string;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          if (ctx) {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            canvas.toBlob(
              (blob) => {
                const convertedFile = new File([blob!], file.name, {
                  type: 'image/jpeg',
                });
                resolve(convertedFile);
              },
              'image/jpeg',
              0.9
            );
          }
        };
        img.onerror = reject;
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!image) {
      setError(t('mainSection.ingredientTable.upload-image'));
      setSnackbarMessage(t('mainSection.ingredientTable.upload-image'));
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    setError(null);
    setLoading(true);
    setLoadingMessage(t('mainSection.ingredientTable.extracting-text'));

    let fileToSend = image;

    // Convert HEIC to JPEG if it's a HEIC file
    if (fileToSend.type === 'image/heic') {
      try {
        fileToSend = await convertHeicToJpeg(fileToSend);
        setLoadingMessage(t('mainSection.ingredientTable.processing-text'));
      } catch (error) {
        setError(t('mainSection.ingredientTable.upload-image'));
        setSnackbarMessage(t('mainSection.ingredientTable.upload-image'));
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        setLoading(false);
        return;
      }
    } else if (fileToSend.type !== 'image/png' && fileToSend.type !== 'image/jpeg') {
      // Convert non-PNG/JPEG files to JPEG
      try {
        fileToSend = await convertImageToJpeg(fileToSend);
        setLoadingMessage(t('mainSection.ingredientTable.processing-text'));
      } catch (error) {
        setError(t('mainSection.ingredientTable.upload-image'));
        setSnackbarMessage(t('mainSection.ingredientTable.upload-image'));
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        setLoading(false);
        return;
      }
    }

    const formData = new FormData();
    formData.append('image', fileToSend);

    try {
      const response = await axios.post(`${BASE_URL}/api/v2/compound_ocr`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setResponse({ ingredients: response.data.ingredients || response.data.ingredient });
      setSnackbarMessage(t('mainSection.ingredientTable.upload-image'));
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (err) {
      setError(t('mainSection.ingredientTable.upload-image'));
      setSnackbarMessage(t('mainSection.ingredientTable.upload-image'));
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const getSafetyColor = (safety: string) => {
    if (safety.toLowerCase().includes('safe') || safety.toLowerCase().includes('安全')) {
      return '#4caf50';
    }
    if (safety.toLowerCase().includes('moderate') 
      || safety.toLowerCase().includes('注意') 
      || safety.toLowerCase().includes('影響')
      || safety.toLowerCase().includes('制限')
    ) {
      return '#ff9800';
    }
    return '#f44336';
  };

  return (
    <Box sx={{ maxWidth: 800, margin: '0 auto', padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        {t('mainSection.ingredientTable.upload-image')}
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          type="file"
          onChange={handleFileChange}
          fullWidth
          margin="normal"
          variant="outlined"
        />
        <Button type="submit" variant="contained" color="primary" fullWidth sx={{ marginTop: 2 }}>
          {t('mainSection.ingredientTable.upload-image')}
        </Button>
      </form>

      {error && (
        <Typography color="error" variant="body2" sx={{ marginTop: 2 }}>
          {error}
        </Typography>
      )}

      {loading && (
        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
          <CircularProgress size={24} sx={{ marginRight: 2 }} />
          <Typography variant="body1">{loadingMessage}</Typography>
        </Box>
      )}

      {response && (
        <Box sx={{ marginTop: 2 }}>
          <Typography variant="h6">{t('mainSection.ingredientTable.title')}</Typography>
          <Table sx={{ marginTop: 2 }}>
            <TableHead>
              <TableRow>
                <TableCell><strong>{t('mainSection.ingredientTable.name')}</strong></TableCell>
                <TableCell><strong>{t('mainSection.ingredientTable.purpose')}</strong></TableCell>
                <TableCell><strong>{t('mainSection.ingredientTable.safety')}</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {response.ingredients.map((ingredient, index) => (
                <TableRow key={index}>
                  <TableCell>{ingredient.name}</TableCell>
                  <TableCell>{ingredient.purpose}</TableCell>
                  <TableCell sx={{ color: getSafetyColor(ingredient.safety) }}>
                    {ingredient.safety}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      )}

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ImageUploadPage;
