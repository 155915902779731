import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Button,
  IconButton,
} from "@mui/material";
import { CopyAll } from "@mui/icons-material";
import { requestWrapper } from "lib/api/auth";
import { BASE_URL, FRONT_URL } from "lib/api/client";
import { useTranslation } from "react-i18next";

interface LinkPreview {
  id: number;
  title: string;
  description: string;
  target_url: string;
  short_url: string;
  image_url: string | null;
  created_at: string;
  updated_at: string;
  user_id: number;
}

const LinkPreviewList: React.FC = () => {
  const { t } = useTranslation("global");
  const [linkPreviews, setLinkPreviews] = useState<LinkPreview[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchLinkPreviews = async () => {
      try {
        const response: LinkPreview[] = await requestWrapper(
          `${BASE_URL}/api/v1/external/link_previews`
        );
        setLinkPreviews(response);
      } catch (err) {
        console.error("Error fetching link previews:", err);
        setError("Failed to fetch link previews.");
      } finally {
        setLoading(false);
      }
    };

    fetchLinkPreviews();
  }, []);

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        {t("mainSection.previews")}
      </Typography>
      <Button
        variant="contained"
        size="medium"
        href={`/url-preview`}
        sx={{ textTransform: "none", marginBottom: "30px" }}
      >
        {t("mainSection.create-previews")}
      </Button>
      {linkPreviews.length === 0 ? (
        <Typography>No link previews found.</Typography>
      ) : (
        <Grid container spacing={2}>
          {linkPreviews.map((preview) => (
            <Grid item xs={12} key={preview.id}>
              <Card sx={{ width: "100%", display: "flex", alignItems: "center" }}>
                {preview.image_url && (
                  <CardMedia
                    component="img"
                    sx={{ width: 140, height: 140 }}
                    image={preview.image_url}
                    alt={preview.title}
                  />
                )}
                <CardContent sx={{ flex: 1 }}>
                  <Typography variant="h6">{preview.title}</Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "blue",
                      cursor: "pointer",
                      textDecoration: "underline",
                      marginBottom: 2,
                    }}
                    onClick={() =>
                      window.open(`${FRONT_URL}/r/${preview.short_url}`, "_blank", "noopener,noreferrer")
                    }
                  >
                    {`${FRONT_URL}/r/${preview.short_url}`}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ marginBottom: 2 }}
                  >
                    {preview.target_url}
                  </Typography>
                  <Box
                    display="flex"
                    alignItems="center"
                  >
                    <Box display="flex" gap={1}>
                      <IconButton
                        onClick={() =>
                          navigator.clipboard.writeText(
                            `${FRONT_URL}/r/${preview.short_url}`
                          )
                        }
                      >
                        <CopyAll fontSize="small" />
                      </IconButton>
                    </Box>
                    <Button
                      variant="outlined"
                      size="small"
                      href={preview.target_url}
                      target="_blank"
                      sx={{ textTransform: "none" }}
                    >
                      Open
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default LinkPreviewList;
