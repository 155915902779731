import React, { useState, useEffect, createContext, ReactNode } from "react";
import { Routes, Route, Navigate, useLocation, useNavigate, matchPath, redirect } from "react-router-dom";
import CommonLayout from "components/layouts/CommonLayout";
import SignUp from "components/pages/SignUp";
import SignIn from "components/pages/SignIn";
import { getCurrentUser } from "lib/api/auth";
import { User } from "interfaces/index";
import ProtectPDFPage from "components/pages/ProtectPDFPage";
import Cookies from "js-cookie";
import SalesforceSettings from "components/pages/external/SalesForceSettings";
import Dashbaord from "components/pages/anaylytics/dashboard/Dashboard";
import MobileWarningDialog from "MobileWarningDialog";
import Flow from "components/pages/anaylytics/dashboard/Flow";
import ChatWorkSettings from "components/pages/external/ChatWorkSettings";
import ScrollToTop from "components/utils/ScrollToTop";
import SearchPage from "components/pages/Search";
import UserProfilePage from "components/pages/UserProfilePage";
import PdfPage from "components/pages/PDFPage";
import AnalyticsPage from "components/pages/anaylytics/Analytics";
import LinkPreviewCreator from "components/external/LinkPreviewCreator";
import RedirectComponent from "components/external/RedirectComponent";
import LinkPreviewList from "components/external/LinkPreview";
import ImageUploadPage from "components/external/projectGoodFood/ImageUploadPage";

interface PrivateRouteProps {
  children: React.ReactElement;
  loading: boolean;
  isSignedIn: boolean;
}

export const AuthContext = createContext({} as {
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isSignedIn: boolean | undefined;
  setIsSignedIn: React.Dispatch<React.SetStateAction<boolean>>;
  currentUser: User | undefined;
  setCurrentUser: React.Dispatch<React.SetStateAction<User | undefined>>;
});

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, loading, isSignedIn }) => {
  if (loading) {
    return <div>Loading...</div>;
  }

  return isSignedIn ? children : <Navigate to="/signin" />;
};

const App: React.FC = () => {
  const stroredSignedIn = Boolean(Cookies.get('jwt') && Cookies.get('user'));
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const [loading, setLoading] = useState<boolean>(false);
  const [isSignedIn, setIsSignedIn] = useState(() => {
    return Boolean(Cookies.get('jwt') && Cookies.get('user'));
  });
  const [isPaidUser, setIsPaidUser] = useState(() => {
    return localStorage.getItem('isPaidUser') === 'false';
  });
  const [currentUser, setCurrentUser] = useState<User | undefined>();
  const [mobileWarningOpen, setMobileWarningOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const unauthenticatedPaths = ["/signup", "/signin", "/shared", "/r/*"];
  const isUnauthenticatedPath = (currentPath: string): boolean => {
    return unauthenticatedPaths.some((path) => matchPath(path, currentPath));
  };

  const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const handleGetCurrentUser = async () => {
      try {
        const res = await getCurrentUser();
        if (res.data.failed) {
          setIsSignedIn(false);
          localStorage.setItem('isSignedIn', isSignedIn.toString());
          setCurrentUser(undefined);
          navigate('/signin')
        }
      } catch (err) {
        setIsSignedIn(false);
        localStorage.setItem('isSignedIn', isSignedIn.toString());
        setCurrentUser(undefined);
      }
  
      setLoading(false);
    };

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
      };
      window.addEventListener("resize", handleResize);
      handleResize();
  
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
  
    useEffect(() => {
      const nonMobilePages = location.pathname.startsWith('/dashboard') 

      if (isMobile && nonMobilePages) {
        setMobileWarningOpen(true);
      } else {
        setMobileWarningOpen(false);
      }
    }, [isMobile, location.pathname]);
  
    useEffect(() => {
      if (!isUnauthenticatedPath(location.pathname)) {
        handleGetCurrentUser();
        const _isPaidUser = Cookies.get("_isPaidUser") === "true";
        setIsPaidUser(_isPaidUser);
      } else {
        setLoading(false);
      }
    }, [isSignedIn, isPaidUser]);
  
    useEffect(() => {
      const handleTokenChange = () => {
        if (!Cookies.get("_access_token") || !Cookies.get("_client") || !Cookies.get("_uid")) {
          setIsSignedIn(false);
          localStorage.setItem('isSignedIn', isSignedIn.toString());
          setCurrentUser(undefined);
        }
      };
  
      window.addEventListener("storage", handleTokenChange);
  
      return () => {
        window.removeEventListener("storage", handleTokenChange);
      };
    }, [isSignedIn]);
  
    return (
      <AuthContext.Provider value={{
        loading,
        setLoading,
        isSignedIn,
        setIsSignedIn,
        currentUser,
        setCurrentUser
      }}>
        {<MobileWarningDialog open={mobileWarningOpen} />}
        {children}
      </AuthContext.Provider>
    );
  };
  
  return (
    <AuthProvider>
      <ScrollToTop />
      <Routes>
        <Route path="/r/:shortUrl" element={<RedirectComponent />} />
        <Route path="/ingredient-analyzer" element={<ImageUploadPage />} />
        <Route
          path="/*"
          element={
            <CommonLayout>
              <Routes>
                <Route path="/signup" element={<SignUp />} />
                <Route path="/signin" element={<SignIn />} />
                <Route path="/shared" element={<ProtectPDFPage />} />
                <Route path="/url-preview" element={<LinkPreviewCreator />} />
                <Route path="/flow" element={<Flow />} />
                <Route path="/settings/salesforce" element={<SalesforceSettings />} />
                <Route path="/settings/chatworks" element={<ChatWorkSettings />} />
                <Route path="/url-previews" element={<LinkPreviewList />} />
                <Route
                  path="/"
                  element={
                    <PrivateRoute
                      children={<SearchPage />}
                      loading={loading}
                      isSignedIn={stroredSignedIn}
                    />
                  }
                />
                <Route
                  path="/dashboard"
                  element={
                    <PrivateRoute
                      children={<Dashbaord />}
                      loading={loading}
                      isSignedIn={stroredSignedIn}
                    />
                  }
                />
                <Route
                  path="/files"
                  element={
                    <PrivateRoute
                      children={<SearchPage />}
                      loading={loading}
                      isSignedIn
                    />
                  }
                />
                <Route
                  path="/me"
                  element={
                    <PrivateRoute
                      children={<UserProfilePage />}
                      loading={loading}
                      isSignedIn={stroredSignedIn}
                    />
                  }
                />
                <Route
                  path="/view"
                  element={
                    <PrivateRoute
                      children={<PdfPage />}
                      loading={loading}
                      isSignedIn={stroredSignedIn}
                    />
                  }
                />
                <Route
                  path="/analytics"
                  element={
                    <PrivateRoute
                      children={<AnalyticsPage />}
                      loading={loading}
                      isSignedIn={stroredSignedIn}
                    />
                  }
                />
              </Routes>
            </CommonLayout>
          }
        />
      </Routes>
    </AuthProvider>
  );  
};

export default App;
