import React, { useState, useContext } from "react"
import { useNavigate, Link } from "react-router-dom"
import Cookies from "js-cookie"
import '../../custom.css';

import { makeStyles, Theme } from "@material-ui/core/styles"
import { Typography } from "@material-ui/core"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardHeader from "@material-ui/core/CardHeader"
import Box from "@material-ui/core/Box"
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';


import { AuthContext } from "App"
import AlertMessage from "components/utils/AlertMessage"
import client, { BASE_URL } from "lib/api/client";
import { Stack } from "@mui/material";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(6),
    width: "100%",
    margin: "0 auto", 
    background: '#fff',
  },
  submitBtn: {
    marginTop: theme.spacing(2),
    flexGrow: 1,
    textTransform: "none"
  },
  header: {
    textAlign: "center"
  },
  card: {
    padding: theme.spacing(2),
    maxWidth: 400,
    margin: "0 auto"
  },
  box: {
    marginTop: "2rem",
    textAlign: "center"
  },
  link: {
    textDecoration: "none"
  }
}));

const SignIn: React.FC = () => {
  const classes = useStyles()
  const navigate = useNavigate();

  const { setIsSignedIn, setCurrentUser } = useContext(AuthContext);

  const [alertMessageOpen, setAlertMessageOpen] = useState<boolean>(false);

  const onSocialSignIn = async (credentialResponse: CredentialResponse) => {
    const credential = credentialResponse.credential;
    if (credential) {
      try {
        const response = await client.post(`${BASE_URL}/api/v1/auths/google`, {
          token: credential,
        });

        const { jwt, user } = response.data;

        Cookies.set('jwt', jwt, { expires: 30 });
        Cookies.set('user', JSON.stringify(user), { expires: 30});

        setIsSignedIn(true)
        setCurrentUser(user)
        navigate("/");

      } catch (error) {
        console.error('Error authenticating with server:', error);
        setAlertMessageOpen(true);
      }
    }
  };

  const onSocialFailure = () => {
    setAlertMessageOpen(true);
  };

  return (
    <div className={classes.container}>
      <form noValidate autoComplete="off">
        <Card className={classes.card}>
          <CardHeader className={classes.header} title="Sign In" />
          <CardContent 
            style={{ 
              display: 'flex',
              justifyContent: 'center', 
              alignItems: 'center', 
              height: '100%'
            }}
          >
            <Stack>
              <GoogleLogin
                onSuccess={onSocialSignIn}
                onError={onSocialFailure}
                useOneTap
              />
              <Box className={classes.box}>
                <Typography variant="body2">
                  Don't have an account? &nbsp;
                  <Link to="/signup" className={classes.link}>
                    Sign Up now!
                  </Link>
                </Typography>
              </Box>
            </Stack>
          </CardContent>
        </Card>
      </form>
      <AlertMessage
        open={alertMessageOpen}
        setOpen={setAlertMessageOpen}
        severity="error"
        message="Invalid email or password"
      />
    </div>
  )
}

export default SignIn
