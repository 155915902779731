import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Typography, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  announcement: {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(0.5),
    paddingTop: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    boxShadow: theme.shadows[1],
    marginBottom: theme.spacing(2),
  },
  text: {
    flex: 1,
    marginRight: theme.spacing(2),
  },
  closeButton: {
    color: theme.palette.text.secondary,
  },
  button: {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
    },
  },
}));

const FeatureAnnouncement: React.FC = () => {
  const { t } = useTranslation("global");
  const classes = useStyles();
  const [visible, setVisible] = useState(true);

  const handleClose = () => {
    setVisible(false);
  };

  const handleLearnMore = () => {
    window.location.href = "/url-previews";
  };

  if (!visible) return null;

  return (
    <Box className={classes.announcement}>
      <Typography variant="body1" className={classes.text}>
        {t("mainSection.anouncement-details")}
      </Typography>
      <Button
        variant="outlined"
        className={classes.button}
        onClick={handleLearnMore}
      >
        {t("mainSection.learn-more")}
      </Button>
      <IconButton className={classes.closeButton} onClick={handleClose}>
        <CloseIcon />
      </IconButton>
    </Box>
  );
};

export default FeatureAnnouncement;
