import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { requestWrapper } from 'lib/api/auth';
import { BASE_URL } from 'lib/api/client';
import { useTranslation } from 'react-i18next';

interface DocumentOptionsProps {
  onOptionChange: (option: string) => void;
}

export interface DocumentOption {
  id: string;
  document_id: string;
  title: string;
}

export const DocumentOptions: React.FC<DocumentOptionsProps> = ({ onOptionChange }) => {
  const [options, setOptions] = useState<DocumentOption[]>([]);
  const [currentValue, setCurrentValue] = useState<string | null>('');
  const [isUpdated, setOptionsUpdated] = useState<boolean>(false);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const documents = await requestWrapper(`${BASE_URL}/api/v1/documents`);
        setOptions(documents);
        if (documents.length > 0 && isUpdated === false) {
          const lastOption = documents[0];
          setCurrentValue(lastOption.title);
          onOptionChange(lastOption.id);
          setOptionsUpdated(true);
        }
      } catch (error) {
        console.error('Failed to fetch options', error);
        setOptions([]);
        onOptionChange('');
      }
    };

    fetchOptions();
  }, [onOptionChange, currentValue]);

  const handleOptionChange = (event: any, value: string | null) => {
    if (value === null || value === '') {
      setCurrentValue('');
      onOptionChange('');
    } else {
      setCurrentValue(value);
      const selectedOption = options.find(option => option.title === value);
      if (selectedOption) {
        onOptionChange(selectedOption.id);
      } else {
        onOptionChange('');
      }
    }
  };

  const { t } = useTranslation("global");
  
  return (
    <Autocomplete
      id="document-option-autocomplete"
      freeSolo
      options={options.map((option) => option.title)}
      value={currentValue}
      onChange={handleOptionChange}
      renderInput={(params) => (
        <TextField {...params} label={t("mainSection.doc-search-label")} variant="outlined" size="small" />
      )}
      style={{ minWidth: "30%" }}
      clearOnEscape
    />
  );
};

export default DocumentOptions;
