import React, { useState, ChangeEvent, FormEvent } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Grid,
  Snackbar,
  Alert,
} from "@mui/material";
import { BASE_URL, FRONT_URL } from "lib/api/client";
import { requestWrapper } from "lib/api/auth";
import { useTranslation } from "react-i18next";

interface LinkPreview {
  id: number;
  title: string;
  description: string;
  target_url: string;
  short_url: string;
  image_url: string;
  created_at: string;
  updated_at: string;
}

type PreviewData = {
  redirect_details: LinkPreview;
};

const LinkPreviewCreator: React.FC = () => {
  const { t } = useTranslation("global");
  
  const [image, setImage] = useState<File | null>(null);
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [targetUrl, setTargetUrl] = useState<string>("");
  const [preview, setPreview] = useState<PreviewData | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setImage(e.target.files[0]);
    }
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (!image || !title || !description || !targetUrl) {
      alert("All fields are required.");
      return;
    }

    const formData = new FormData();
    formData.append("image", image);
    formData.append("title", title);
    formData.append("description", description);
    formData.append("target_url", targetUrl);

    try {
      const data: PreviewData = await requestWrapper(`${BASE_URL}/api/v1/external/link_previews`, {
        method: 'POST',
        body: formData,
      });

      setPreview(data);
    } catch (error) {
      console.error("Error creating link preview:", error);
      alert("Failed to create link preview. Please try again.");
    }
  };

  const handleCopy = () => {
    if (preview) {
      try {
        navigator.clipboard.writeText(`${FRONT_URL}/r/${preview.redirect_details.short_url}`);
        setSnackbarOpen(true);
      } catch (error) {
        console.error("Failed to copy link:", error);
      }
    }
  };

  return (
    <Box sx={{ maxWidth: 600, mx: "auto", mt: 4, p: 2, border: "1px solid #ddd", borderRadius: 2 }}>
      <Typography variant="h4" align="center" gutterBottom>
        {t("mainSection.previews-url-title")}
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={t("mainSection.title-placeholder")}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={t("mainSection.description")}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              multiline
              rows={3}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={t("mainSection.upload-target-url")}
              value={targetUrl}
              onChange={(e) => setTargetUrl(e.target.value)}
              type="url"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" component="label">
              {t("mainSection.upload-image")}
              <input type="file" hidden accept="image/*" onChange={handleImageChange} />
            </Button>
            {image && <Typography sx={{ mt: 1 }}>{image.name}</Typography>}
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              {t("mainSection.create-link")}
            </Button>
          </Grid>
        </Grid>
      </form>

      {preview && (
        <Box sx={{ mt: 4, textAlign: "center" }}>
          <Typography variant="h5">
            {t("mainSection.shorten-link")}
          </Typography>
          <Typography sx={{ mt: 2 }}>
            <a href={`${FRONT_URL}/r/${preview.redirect_details.short_url}`} target="_blank" rel="noopener noreferrer">
              {`${FRONT_URL}/r/${preview.redirect_details.short_url}`}
            </a>
          </Typography>
          <Button sx={{ mt: 2 }} variant="outlined" onClick={handleCopy}>
            {t("mainSection.copy-link")}
          </Button>
        </Box>
      )}

      {preview && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h4" gutterBottom>
            {t("mainSection.social-media-previews")}
          </Typography>
          <Grid container spacing={2}>
            {/* Facebook Preview */}
            <Grid item xs={12} sm={6}>
              <Card sx={{ p: 2, backgroundColor: "#f0f2f5", borderRadius: 2 }}>
                <Typography variant="subtitle1">Facebook Preview</Typography>
                {image && (
                  <CardMedia
                    component="img"
                    height="140"
                    image={URL.createObjectURL(image)}
                    alt="Preview Image"
                  />
                )}
                <CardContent>
                  <Typography gutterBottom variant="h6">
                    {title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {description}
                  </Typography>
                  <Typography variant="body2" color="primary" sx={{ mt: 1 }}>
                    {`${FRONT_URL}/r/${preview.redirect_details.short_url}`}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            
            {/* WhatsApp Preview */}
            <Grid item xs={12} sm={6}>
              <Card sx={{ p: 2, backgroundColor: '#e6ffe6', borderRadius: 2 }}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  WhatsApp Preview
                  {t("mainSection.whatsapp-previews")}
                </Typography>
                <Typography variant="body2" color="text.primary">
                  {description} {`${FRONT_URL}/r/${preview.redirect_details.short_url}`}
                </Typography>
              </Card>
            </Grid>
            {/* Slack Preview */}
            <Grid item xs={12} sm={6}>
              <Card sx={{ p: 2, backgroundColor: '#4a154b', color: 'white', borderRadius: 2 }}>
                <Typography variant="subtitle1" sx={{ mb: 1, color: 'white' }}>
                  Slack Preview
                  {t("mainSection.slack-previews")}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <img
                    src={URL.createObjectURL(image!)}
                    alt="Preview"
                    style={{ height: '50px', width: '50px', borderRadius: '4px', marginRight: '8px' }}
                  />
                  <Box>
                    <Typography variant="body2">{title}</Typography>
                    <Typography variant="caption" color="gray">
                      {`${FRONT_URL}/r/${preview.redirect_details.short_url}`}
                    </Typography>
                  </Box>
                </Box>
                <Typography variant="body2">{description}</Typography>
              </Card>
            </Grid>
            {/* Twitter Preview */}
            <Grid item xs={12} sm={6}>
              <Card sx={{ p: 2, backgroundColor: '#e1f5fe', borderRadius: 2 }}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  {t("mainSection.twitter-previews")}
                </Typography>
                <Typography variant="body2">
                  {title} - {description} {`${FRONT_URL}/r/${preview.redirect_details.short_url}`}
                </Typography>
              </Card>
            </Grid>
            {/* Messenger Preview */}
            <Grid item xs={12} sm={6}>
              <Card sx={{ p: 2, backgroundColor: '#e3f2fd', borderRadius: 2 }}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  {t("mainSection.messenger-previews")}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    src={URL.createObjectURL(image!)}
                    alt="Preview"
                    style={{ height: '50px', width: '50px', borderRadius: '4px', marginRight: '8px' }}
                  />
                  <Box>
                    <Typography variant="body2">{description}</Typography>
                    <Typography variant="caption" color="gray">
                      {`${FRONT_URL}/r/${preview.redirect_details.short_url}`}
                    </Typography>
                  </Box>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Box>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity="success" onClose={() => setSnackbarOpen(false)}>
          Link copied to clipboard!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default LinkPreviewCreator;