import React, { useState, useEffect } from 'react';
import { TextField, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Stack, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Snackbar, Alert } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import debounce from 'lodash/debounce';
import { useNavigate } from 'react-router-dom';
import { requestWrapper } from 'lib/api/auth';
import { formatDateString } from 'helpers/dateHelper';
import { BASE_URL } from 'lib/api/client';
import { useTranslation } from 'react-i18next';
import FeatureAnnouncement from 'components/external/FeatureAnnouncement';

export interface DocumentProps {
  id: string;
  title: string;
  created_at: string;
  file_url: string;
  page_urls: string[];
}

export default function SearchPage() {
  const [searchText, setSearchText] = useState('');
  const [documents, setDocuments] = useState<DocumentProps[]>([]);
  const [filteredDocuments, setFilteredDocuments] = useState<DocumentProps[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const [deleteTitle, setDeleteTitle] = useState<string | null>(null);
  const [confirmationText, setConfirmationText] = useState('');
  const [confirmationError, setConfirmationError] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null);
  const navigate = useNavigate();
  const { t } = useTranslation("global");

  const columns: { id: keyof DocumentProps; label: string; minWidth: number }[] = [
    { id: 'title', label: t("mainSection.doc-table-title-column"), minWidth: 170 },
    { id: 'created_at', label: t("mainSection.created-date"), minWidth: 110 },
    { id: 'id', label: t("mainSection.delete"), minWidth: 50 }, // Adding a column for the delete button
  ];

  useEffect(() => {
    const fetchDocuments = async () => {
      requestWrapper(`${BASE_URL}/api/v1/documents`)
        .then(response => {
          setDocuments(response);
          setFilteredDocuments(response);
        })
        .catch(error => {
          console.error('Error updating user data:', error);
          if (error === "Authentication tokens are missing.") {
            window.location.href = window.location.href;
          }
        });
    };

    fetchDocuments();
  }, [navigate]);

  useEffect(() => {
    const debouncedSearch = debounce(() => {
      const results = searchText ? documents.filter(doc =>
        doc.title.toLowerCase().includes(searchText.toLowerCase())) : documents;
      setFilteredDocuments(results);
    }, 300);

    debouncedSearch();
    return () => debouncedSearch.cancel();
  }, [searchText, documents]);

  const handleRowClick = (id: string) => {
    navigate(`/view?docuId=${id}`);
  };

  const handleDeleteClick = (id: string, title: string) => {
    setDeleteId(id);
    setDeleteTitle(title);
    setConfirmationText('');
    setConfirmationError('');
    setOpenDialog(true);
  };

  const handleConfirmDelete = async () => {
    if (confirmationText === "DELETE") {
      if (deleteId && deleteTitle) {
        try {
          await requestWrapper(`${BASE_URL}/api/v1/documents/${deleteId}`, {
            method: 'DELETE',
          });
          setDocuments(documents.filter(doc => doc.id !== deleteId));
          setFilteredDocuments(filteredDocuments.filter(doc => doc.id !== deleteId));
          setSnackbarMessage(`"${deleteTitle}"を削除しました。`);
          setOpenDialog(false);
        } catch (error) {
          console.error('Error deleting document:', error);
          setSnackbarMessage('資料の削除中にエラーが発生しました。');
        }
      }
    } else {
      setConfirmationError('正確に「DELETE」と入力してください。');
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarMessage(null);
  };

  return (
    <Stack direction="column" alignItems="center" spacing={2}>
      <FeatureAnnouncement/>
      <TextField
        style={{ minWidth: '350px' }}
        label={t("mainSection.doc-search-label")}
        variant="outlined"
        onChange={(event) => setSearchText(event.target.value)}
      />
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align="left"
                    sx={{
                      minWidth: column.minWidth,
                      backgroundColor: '#d6d6d6',
                      color: '#333',
                      fontWeight: 'bold'
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredDocuments.map((document) => (
                <TableRow key={document.id} hover onClick={() => handleRowClick(document.id)}>
                  <TableCell align="left">{document.title}</TableCell>
                  <TableCell align="left">{formatDateString(document.created_at)}</TableCell>
                  <TableCell align="left">
                    <IconButton
                      aria-label="delete"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteClick(document.id, document.title);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {/* Confirmation Dialog */}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">
          {`${deleteTitle} ${t("mainSection.delete-confirmation-title")}`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            {t("mainSection.delete-confirmation-detail-1")}<strong>{t("mainSection.delete-confirmation-detail-2")}</strong><br />
            {t("mainSection.delete-confirmation-detail-2")}<strong>{t("mainSection.delete-confirmation-detail-4")}</strong><br /><br />
            {t("mainSection.delete-confirmation-detail-5")}<br /><br />
            {t("mainSection.delete-confirmation-detail-6")}<strong>{t("mainSection.delete-confirmation-detail-7")}</strong>{t("mainSection.delete-confirmation-detail-8")}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="DELETE"
            fullWidth
            variant="outlined"
            value={confirmationText}
            onChange={(e) => setConfirmationText(e.target.value)}
            error={Boolean(confirmationError)}
            helperText={confirmationError}
            sx={{
              '& .MuiOutlinedInput-root.Mui-error': {
                '& fieldset': {
                  borderColor: 'red',
                },
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            {t("mainSection.cancel")}
          </Button>
          <Button onClick={handleConfirmDelete} color="secondary">
          {t("mainSection.delete")}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for feedback */}
      <Snackbar
        open={Boolean(snackbarMessage)}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="info">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Stack>
  );
}
