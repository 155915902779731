import { BASE_URL, LANDING_PAGE_URL } from "lib/api/client";
import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";


interface LinkPreview {
  id: number;
  title: string;
  description: string;
  target_url: string;
  short_url: string;
  image_url: string;
  created_at: string;
  updated_at: string;
}

type PreviewData = {
  redirect_details: LinkPreview;
};

const RedirectComponent: React.FC = () => {
  const { shortUrl } = useParams<{ shortUrl: string }>();
  const navigate = useNavigate();


  useEffect(() => {
    const fetchTargetUrlAndRedirect = async () => {
      if (!shortUrl) {
        console.error("Short URL not provided.");
        navigate(LANDING_PAGE_URL);
        return;
      }

      try {
        const response = await fetch(`${BASE_URL}/api/v1/external/r/${shortUrl}`);
        if (response.ok) {
          const data: PreviewData = await response.json();
          const targetUrl = data.redirect_details.target_url;

          console.log(data);
          window.location.href = targetUrl;
        } else {
          console.error("Failed to fetch target URL:", response.status);
          navigate(LANDING_PAGE_URL);
        }
      } catch (error) {
        console.error("Error fetching target URL:", error);
        navigate(LANDING_PAGE_URL);
      }
    };

    fetchTargetUrlAndRedirect();
  }, [shortUrl, navigate]);

  return null;
};

export default RedirectComponent;
